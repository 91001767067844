import { JSX } from "react"
import { NavLink } from "react-router-dom"

function NotFound(): JSX.Element {
    return (
        <div className="not-found h-full flex items-center justify-center">
            <div className="flex h-auto flex-col items-center px-6 py-8">
                <div className="flex-none flex">
                    <NavLink to="/">
                        <img src="/gemt-logo-neu.png" id="logo-center" alt="gemt" />
                    </NavLink>
                </div>
                <div className="w-full max-w-md">
                    <div className="space-y-2 p-6">
                        <h1 className="text-2xl text-center font-medium leading-tight tracking-tight text-white">
                            This page does not exist.
                        </h1>
                        <h2 className="text-2xl text-center font-medium leading-tight tracking-tight text-secondary-color-dark">404</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
