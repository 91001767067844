import { Panel, PanelGroup } from "react-resizable-panels"
import RowsLoader from "../components/RowsLoader"
import LinkRow from "./LinkRow"
import { Link } from "../stores/LinksStore"
import { observer } from "mobx-react-lite"
import EmptyCollection from "./EmptyCollection"

interface CollectionProps {
    links: Link[]
    showCollectionName: boolean
    showFavoriteStatus: boolean
    linksView: string
    isLoading: boolean
}

const LinksCollection = observer(({
    links,
    showCollectionName,
    showFavoriteStatus,
    linksView,
    isLoading,
}: CollectionProps) => {

    if (isLoading == true) {
        return <RowsLoader />
    }

    if (links?.length === 0) {
        return <EmptyCollection />
    }

    return (
        <div className="flex-1 h-[calc(100%-78px)]">
            <PanelGroup direction="horizontal" className="">
                <Panel minSize={50} className="" id="center" order={1}>
                    <div className="h-full overflow-y-auto">
                        <ul className="list-none">
                            {links?.map((link: Link) => (
                                <LinkRow
                                    key={link.id}
                                    link={link}
                                    showCollectionName={showCollectionName}
                                    showFavoriteStatus={showFavoriteStatus}
                                    linksView={linksView}
                                />
                            ))}
                        </ul>
                    </div>
                </Panel>
            </PanelGroup>
        </div>
    )
})

export default LinksCollection
