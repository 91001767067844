import axios from "axios"
import AppConfig from "../AppConfig"
import { Logger } from "../utilities/logger"

interface ICollection {
    id: string
    title: string
    archived: boolean
    vaultId: string
}

interface ICollectionResponse {
    data: ICollection
}

interface ICollectionsResponse {
    data: ICollection[]
}

const baseUrl = `${AppConfig.host}/${AppConfig.api}/v1/vaults`

export class CollectionService {
    serviceLogger = new Logger("CollectionsService")

    constructor() {
        // set props normally
        // nothing async can go here
    }

    public async getCollections(vaultId: string, authToken: string): Promise<ICollection[]> {
        const url = `${baseUrl}/${vaultId}/collections`
        try {
            const response = await axios.get<ICollectionsResponse>(url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authToken,
                },
                validateStatus: () => true,
            })

            // this.serviceLogger.debug(JSON.stringify(response.data, null, 4))
            return response.data.data
        } catch (error: any) {
            this.serviceLogger.error("Error fetching collections:", error)
            throw error // Re-throw the error for the calling function to handle
        }
    }

    public async createCollection(name: string, vaultId: string, authToken: string): Promise<ICollection> {
        const url = `${baseUrl}/${vaultId}/collections`

        const response = await axios.post<ICollectionResponse>(url, {
            title: name
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        }
        )

        return response.data.data
    }

    public async updateCollection(name: string, collectionId: string, vaultId: string, authToken: string): Promise<ICollection> {
        const url = `${baseUrl}/${vaultId}/collections/${collectionId}`
        // try {
        const response = await axios.patch<ICollectionResponse>(url, {
            title: name
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        }
        )

        return response.data.data
    }
}
