import React, { useState, useContext, JSX } from "react"
import { AuthContext } from "../context/AuthContext"
import { NavLink } from "react-router-dom"

function Register(): JSX.Element {
    const auth = useContext(AuthContext)

    const [email, setEmail] = useState<string>()
    const [registered, setRegistered] = useState<boolean>(false)

    const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false)

    // Check if both the email and checkbox are valid
    const isFormValid = email && isTermsChecked

    async function handleRegistration(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault()
        const regd = await auth.register(email!)
        if (regd) {
            setRegistered(true)
        }
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsTermsChecked(e.target.checked)
    }

    return (
        <div className="register h-full flex items-center justify-center">
            <div className="h-auto flex flex-col items-center px-6 py-8">
                <div className="flex-none">
                    <NavLink to="/">
                        <img src="/gemt-logo-neu.png" id="logo-center" alt="gemt" />
                    </NavLink>
                </div>
                <div className="w-full max-w-md">
                    <div className="space-y-2 p-6">
                        {registered ? (
                            <div>
                                <p className="text-secondary-color-dark text-normal text-center font-light">
                                    Thank you for registering.<br />
                                    You can now <NavLink className="font-medium text-indigo-500 hover:underline"
                                        to="/login"
                                    >
                                        Login
                                    </NavLink>
                                </p>
                            </div>
                        ) : (
                            <form
                                className="space-y-4 md:space-y-6"
                                onSubmit={handleRegistration}
                            >
                                <h1 className="text-xl text-center font-medium leading-tight tracking-tight text-white">
                                    Register with your email address
                                </h1>
                                <div>
                                    <input
                                        type="email"
                                        data-1p-ignore="true"
                                        name="email"
                                        id="email"
                                        className="block w-full rounded-lg border border-border-dark focus:border-indigo-600 bg-transparent p-3 text-white placeholder-gray-400 focus:outline-none focus:ring-0"
                                        placeholder="Enter your email address..."
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>

                                <div>
                                    <label className="text-secondary-color-dark text-sm font-light flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            checked={isTermsChecked}
                                            onChange={handleCheckboxChange}
                                            className="h-4 w-4"
                                        />
                                        <span>I agree to the <NavLink
                                            className="font-medium text-indigo-500 hover:underline" to="/privacy">
                                                Privacy Policy</NavLink> and <NavLink
                                            className="font-medium text-indigo-500 hover:underline" to="/terms">
                                                Terms of Service</NavLink>.</span>
                                    </label>
                                </div>

                                <button
                                    type="submit"
                                    disabled={!isFormValid}
                                    data-umami-event="register-button"
                                    className={`w-full bg-primary-accent-dark rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4
                                        ${isFormValid ? "hover:bg-indigo-600 focus:ring-primary-accent-dark" : "opacity-50"}`}
                                >
                                    Register
                                </button>
                                <p className="text-secondary-color-dark text-sm text-center font-light">
                                    Already have an account?{" "}
                                    <NavLink className="font-medium text-indigo-500 hover:underline"
                                        to="/login"
                                    >
                                        Login
                                    </NavLink>
                                </p>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
