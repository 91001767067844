import { useContext, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"
import LinksResults from "../components/LinksResults"
import SearchToolbar from "../components/SearchToolbar"
import { useStores } from "../stores/RootStore"
import { observer } from "mobx-react-lite"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"

const Search = observer(() => {
    const authContext = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const { searchStore } = useStores()

    const navigate = useNavigate()

    const query = searchParams.get("q")
    const lang = searchParams.get("lang")

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        searchStore.searchForLinks(query, lang, authToken)
    }, [query, lang])

    if (!query) {
        return <div className="search flex h-full flex-col">

            <SearchToolbar query={query} />

            <div className="h-[calc(100%-48px)] overflow-y-auto pb-10 pt-3">
                <div className="h-auto text-white text-sm pb-3 px-6">Recent searches</div>
                <ul className="list-none text-secondary-color-dark">
                    {searchStore.searchHistory?.map((search) => (
                        <li key={search.query} className="h-8" onClick={() => { navigate(`/links/search?q=${search.query}`) }}>
                            <div className="flex flex-row gap-2 items-center hover:bg-content-highlight-dark px-6">
                                <MagnifyingGlassIcon className="h-3 w-3" />
                                <div className="text-white h-6">{search.query}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

        </div >
    }

    return (
        <div className="search flex h-full flex-col">

            <SearchToolbar query={query} />

            <LinksResults
                links={searchStore.results}
                showCollectionName={true}
                showFavoriteStatus={true}
                linksView={"THIN"}
                isLoading={searchStore.isLoading} />

        </div>
    )
})

export default Search
