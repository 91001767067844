import SidebarContents from "./SidebarContents"
// import AddLinkButton from "./AddLinkButton"

function Sidebar() {
    return (
        <div className="sidebar h-screen overflow-hidden font-medium">
            <SidebarContents />
        </div>
    )
}

export default Sidebar
