import { Dialog } from "@headlessui/react"
import React, { useState, useContext, useRef, Dispatch, SetStateAction } from "react"
import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/20/solid"
import { AuthContext } from "../context/AuthContext"
import { useStores } from "../stores/RootStore"


interface Props {
    isCreateTagDialogOpen: boolean
    setCreateTagDialogOpen: Dispatch<SetStateAction<boolean>>
}

const CreateTagDialog = ({ isCreateTagDialogOpen, setCreateTagDialogOpen }: Props) => {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()
    const { tagsStore } = useStores()

    let [tagName, setTagName] = useState<string>("")
    let tagNameRef = useRef(null)

    let [isMutating, setIsMutating] = useState<boolean>(false)

    function closeModal() {
        setCreateTagDialogOpen(false)
        setTagName("")
    }

    async function handleCreateTag(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault()
        setIsMutating(true)
        tagsStore.createTag(tagName, authToken)
        closeModal()
        setIsMutating(false)
    }

    return (
        <Dialog
            open={isCreateTagDialogOpen}
            onClose={() => { }}
            className="relative z-50"
            initialFocus={tagNameRef}
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-black/20" aria-hidden="true" />

            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                <Dialog.Panel className="h-auto bg-modal-dark w-full max-w-md transform overflow-hidden rounded-md text-left align-middle primary-color-dark shadow-xl transition-all">
                    <div className="flex text-primary-color-dark flex-row px-3 pt-3">
                        <ChevronRightIcon className="h-5 w-5" />
                        <div className="text-sm">New Tag</div>
                        <button
                            className="text-secondary-color-dark ml-auto rounded border border-transparent text-xs hover:text-primary-color-dark"
                            onClick={closeModal}
                            data-umami-event="create-tag-button"
                        >
                            <XMarkIcon className="h-5 w-5" />
                        </button>
                    </div>

                    <form
                        className="space-y-2 pt-1"
                        onSubmit={handleCreateTag}
                    >
                        <div className="flex-column flex px-3">
                            <input
                                type="text"
                                data-1p-ignore="true"
                                ref={tagNameRef}
                                name="name"
                                id="name"
                                className="bg-modal-dark placeholder-secondary-color-dark block h-8 w-full grow resize-none overflow-hidden py-2 text-primary-color-dark focus:border-0 focus:outline-none focus:ring-0"
                                placeholder="Tag name"
                                onChange={(e) => setTagName(e.target.value)}
                            />
                        </div>

                        <div className="mt-3 flex flex-row-reverse border-t border-gray-700 px-3 py-3">
                            <button
                                type="submit"
                                disabled={isMutating || !tagName}
                                className="rounded bg-primary-accent-dark px-4 py-1.5 text-sm font-medium text-primary-color-dark hover:bg-indigo-600 focus:ring-primary-accent-dark"
                            >
                                Create Tag
                            </button>
                        </div>
                    </form>
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}

export default CreateTagDialog
