import axios from "axios"
import AppConfig from "../AppConfig"

interface ITag {
    id: string
    name: string
    vaultId: string
    matchCount: number
}

interface ITagResponse {
    data: ITag
}

interface ITagsResponse {
    data: ITag[]
}

const baseUrl = `${AppConfig.host}/${AppConfig.api}/v1/vaults`

export class TagsService {

    constructor() {
        // set props normally
        // nothing async can go here
    }

    public async getTags(vaultId: string, authToken: string): Promise<ITag[]> {
        const url = `${baseUrl}/${vaultId}/tags`
        // try {
        const response = await axios.get<ITagsResponse>(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async createTag(name: string, vaultId: string, authToken: string): Promise<ITag> {
        const url = `${baseUrl}/${vaultId}/tags`
        // try {
        const response = await axios.post<ITagResponse>(url, {
            name: name
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        }
        )

        return response.data.data
    }
}
