import { HeartIcon } from "@heroicons/react/24/outline"

function RowsLoader() {
    return (
        <div className="loader">
            <div className="h-full overflow-y-auto pb-36">
                <ul className="list-none animate-pulse">
                    <li className="font-medium border-border-dark hover:bg-content-highlight-dark flex flex-1 flex-row items-center gap-1.5 border-b text-sm py-1 px-6 h-11">
                        <div className="bg-tertiery-color-dark rounded-full h-3 w-3"></div>
                        <div className="h-3 w-1/2 bg-tertiery-color-dark rounded"></div>
                        <div className="ml-auto">
                            <div className="flex flex-row">
                                <div className="m-auto hidden whitespace-nowrap h-2 w-12 bg-tertiery-color-dark rounded md:block"></div>
                                <HeartIcon className="text-slate-700 ml-2 h-5 w-5" />
                            </div>
                        </div>
                    </li>
                    <li className="font-medium border-border-dark hover:bg-content-highlight-dark flex flex-1 flex-row items-center gap-1.5 border-b text-sm py-1 px-6 h-11">
                        <div className="bg-tertiery-color-dark rounded-full h-3 w-3"></div>
                        <div className="h-3 w-2/3 bg-tertiery-color-dark rounded"></div>
                        <div className="ml-auto">
                            <div className="flex flex-row">
                                <div className="m-auto hidden whitespace-nowrap h-2 w-12 bg-tertiery-color-dark rounded md:block"></div>
                                <HeartIcon className="text-slate-700 ml-2 h-5 w-5" />
                            </div>
                        </div>
                    </li>
                    <li className="font-medium border-border-dark hover:bg-content-highlight-dark flex flex-1 flex-row items-center gap-1.5 border-b text-sm py-1 px-6 h-11">
                        <div className="bg-tertiery-color-dark rounded-full h-3 w-3"></div>
                        <div className="h-3 w-1/3 bg-tertiery-color-dark rounded"></div>
                        <div className="ml-auto">
                            <div className="flex flex-row">
                                <div className="m-auto hidden whitespace-nowrap h-2 w-12 bg-tertiery-color-dark rounded md:block"></div>
                                <HeartIcon className="text-slate-700 ml-2 h-5 w-5" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default RowsLoader
