import { PlusCircleIcon } from "@heroicons/react/20/solid"
import { useState } from "react"
import CreateCollectionDialog from "./CreateCollectionDialog"

const AddCollectionButton = () => {


    let [isCreateCollectionDialogOpen, setCreateCollectionDialogOpen] = useState<boolean>(false)

    function openModal() {
        setCreateCollectionDialogOpen(true)
    }

    return (
        <div className="new h-auto">
            <button
                onClick={openModal}
                data-umami-event="add-collection-button"
                className="text-secondary-color-dark flex gap-1.5 p-2 text-sm hover:text-primary-color-dark"
            >
                <PlusCircleIcon className="h-5 w-5" />
                <p className="">New Collection</p>
            </button>

            <CreateCollectionDialog isCreateCollectionDialogOpen={isCreateCollectionDialogOpen} setCreateCollectionDialogOpen={setCreateCollectionDialogOpen} />
        </div>
    )
}

export default AddCollectionButton
