import axios from "axios"
import AppConfig from "../AppConfig"
import { ILink, ILinksResponse } from "./LinkService"

const baseUrl = `${AppConfig.host}/${AppConfig.api}/v1`

export class SearchService {

    constructor() {
        // set props normally
        // nothing async can go here
    }

    public async search(vaultId: string, authToken: string, searchString: string, lang?: string | null,): Promise<ILink[]> {
        const url = `${baseUrl}/search?q=${searchString}&lang=${lang}&vaultId=${vaultId}`
        // try {
        const response = await axios.get<ILinksResponse>(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }
}
