import { useOutlet } from "react-router-dom"
import NoSelection from "../components/NoSelection"
import Sidebar from "../components/Sidebar"
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"

const Content = () => {

    const outlet = useOutlet()

    return (
        <PanelGroup direction="horizontal" className="links">
            <Panel defaultSize={18} minSize={18} className="hidden lg:block" id="sidebar" order={1}>
                <Sidebar />
            </Panel>
            <PanelResizeHandle className="w-px" />
            <Panel minSize={70} className="lg:pt-3 lg:pb-3 lg:pr-3" id="contents" order={2}>
                <div className="h-full">
                    <div className="content h-full border border-border-dark lg:rounded-lg bg-content-dark">
                        {outlet || <NoSelection />}
                    </div>
                </div>
            </Panel>
        </PanelGroup>
    )
}

export default Content
