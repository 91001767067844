import AppConfig from "../AppConfig"
import axios from "axios"
import { isSuccessResponse } from "../utilities/response"
import { Logger } from "../utilities/logger"

export class AuthService {
    public static logger = new Logger("AuthService")
    public static async registerUser(credentials: { email: string }): Promise<boolean> {
        try {
            const url = `${AppConfig.host}/${AppConfig.api}/register`

            const response = await axios.post<string>(url,
                {
                    email: credentials.email
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    validateStatus: () => true,
                }
            )

            return isSuccessResponse(response.status)
        } catch (error) {
            this.logger.error("Error:", error)
            return false
        }
    }

    public static async loginUser(credentials: { email: string }): Promise<boolean> {
        try {
            const url = `${AppConfig.host}/${AppConfig.api}/login`

            const response = await axios.post<string>(url,
                {
                    email: credentials.email
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    validateStatus: () => true,
                }
            )

            return isSuccessResponse(response.status)
        } catch (error) {
            this.logger.error("Error:", error)
            return false
        }
    }

    public static async authorizeUser(authorization: { email?: string, accessToken?: string }): Promise<any> {
        try {
            const url = `${AppConfig.host}/${AppConfig.api}/authorize`

            const response = await axios.post<string>(url,
                {
                    email: authorization.email,
                    accessToken: authorization.accessToken
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    validateStatus: () => true,
                }
            )

            if (isSuccessResponse(response.status)) {
                return response.data
            } else {
                this.logger.error("Error message:", response.statusText)
                return undefined
            }
        } catch (error) {
            this.logger.error("Error:", error)
            return undefined
        }
    }
}
