import axios from "axios"
import AppConfig from "../AppConfig"
import { Logger } from "../utilities/logger"

const baseUrl = `${AppConfig.host}/${AppConfig.api}/v1`
const vaultsUrl = `${baseUrl}/vaults`

export interface ILink {
    id: string;
    url: string;
    host: string;
    scheme: string;
    title: string;
    description: string;
    imageUrl: string;
    notes: string | null;
    favorite: boolean;
    archived: boolean;
    linkType: string;
    tags: string[];
    vaultId: string;
    collectionId: string;
    creatorId: string;
    indexedAt: string;
    createdAt: string;
    modifiedAt: string;
}

export interface ILinkResponse {
    data: ILink
}

export interface ILinksResponse {
    data: ILink[]
}

export class LinkService {
    serviceLogger = new Logger("LinkService")

    public async addLink(newUrl: string, vaultId: string, authToken: string, collectionId?: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks`
        // try {
        var payload: any = {
            "url": newUrl,
        }

        if (collectionId) {
            this.serviceLogger.debug("add collection id to link")
            payload["collectionId"] = collectionId
        }

        const response = await axios.post<ILinkResponse>(
            url,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authToken,
                },
                validateStatus: () => true,
            })

        return response.data.data
    }

    public async addTagsToLink(tagIds: string[], linkId: string, vaultId: string, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}/tags`
        // try {
        var payload: any = {
            "tags": tagIds,
        }

        const response = await axios.post<ILinkResponse>(
            url,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authToken,
                },
                validateStatus: () => true,
            })

        return response.data.data
    }

    public async removeTagsFromLink(tagIds: string[], linkId: string, vaultId: string, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}/tags`
        // try {
        var payload: any = {
            "tags": tagIds,
        }

        const response = await axios.delete<ILinkResponse>(
            url,
            {
                data: payload,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authToken,
                },
                validateStatus: () => true,
            })

        return response.data.data
    }

    public async getLinksForCategory(category: string, vaultId: string, authToken: string): Promise<ILink[]> {
        var url = `${vaultsUrl}/${vaultId}/bookmarks`
        if (category === "all") {
            url = url += "?filter[archived]=false"
        }
        if (category === "favorites") {
            url = url += "?filter[favorite]=true&filter[archived]=false"
        }
        if (category === "uncategorized") {
            url = url += "?filter[uncategorized]=true&filter[archived]=false"
        }
        if (category === "archived") {
            url = url += "?filter[archived]=true"
        }

        // try {
        const response = await axios.get<ILinksResponse>(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async getLinksForCollection(vaultId: string, collectionId: string, authToken: string): Promise<ILink[]> {

        const url = `${vaultsUrl}/${vaultId}/collections/${collectionId}/bookmarks`
        // try {
        const response = await axios.get<ILinksResponse>(`${url}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async getLinksForTag(vaultId: string, tagId: string, authToken: string): Promise<ILink[]> {

        const url = `${vaultsUrl}/${vaultId}/tags/${tagId}/bookmarks`
        // try {
        const response = await axios.get<ILinksResponse>(`${url}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async getLink(vaultId: string, linkId: string, authToken: string): Promise<ILink> {

        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}`
        // try {
        const response = await axios.get<ILinkResponse>(`${url}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }


    public async indexLink(vaultId: string, linkId: string, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}/index`
        // try {
        const response = await axios.get<ILinkResponse>(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async search(vaultId: string, authToken: string, searchString: string, lang?: string | null,): Promise<ILink[]> {
        const url = `${baseUrl}/search?q=${searchString}&lang=${lang}&vaultId=${vaultId}`
        // try {
        const response = await axios.get<ILinksResponse>(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async toggleFavorite(vaultId: string, linkId: string, favorite: boolean, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}`
        // try {
        const response = await axios.patch<ILinkResponse>(url, {
            "favorite": favorite
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async toggleArchived(vaultId: string, linkId: string, archived: boolean, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}`
        // try {
        const response = await axios.patch<ILinkResponse>(url, {
            "archived": archived
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async updateTitle(vaultId: string, linkId: string, title: string, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}`
        // try {
        const response = await axios.patch<ILinkResponse>(url, {
            "title": title
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async updateDescription(vaultId: string, linkId: string, description: string, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}`
        // try {
        const response = await axios.patch<ILinkResponse>(url, {
            "description": description
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async updateNotes(vaultId: string, linkId: string, notes: string, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}`
        // try {
        const response = await axios.patch<ILinkResponse>(url, {
            "notes": notes
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }

    public async updateCollection(vaultId: string, linkId: string, collectionId: string, authToken: string): Promise<ILink> {
        const url = `${vaultsUrl}/${vaultId}/bookmarks/${linkId}`
        // try {
        const response = await axios.patch<ILinkResponse>(url, {
            "collectionId": collectionId
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            },
            validateStatus: () => true,
        })

        return response.data.data
    }
}
