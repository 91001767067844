import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/20/solid"
import { useEffect, useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { observer } from "mobx-react-lite"
import { useStores } from "../stores/RootStore"
import CollectionRow from "./CollectionRow"
import { Logger } from "../utilities/logger"


const Collections = observer(() => {
    const componentLogger = new Logger("Collections")

    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { collectionsStore } = useStores()

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        componentLogger.debug("fetch collections")
        collectionsStore.fetchCollections(authToken)
    }, [collectionsStore])

    if (collectionsStore.isLoading) {
        componentLogger.debug("loading collections")
    } else {
        componentLogger.debug("display collection")
    }

    if (collectionsStore.error) {
        componentLogger.error("error collections")
        return <div>CGI Error: {collectionsStore.error}</div>
    }

    return (
        <div className="collections">
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="pl-2 h-4 text-secondary-color-dark flex w-full justify-between text-left text-xs font-semibold">
                            <span>Collections</span>
                            <ChevronUpIcon
                                className={`${open ? "rotate-180 transform" : ""} h-5 w-5`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="">
                            <ul className="h-auto list-none">
                                {collectionsStore.collections?.map((c) => (
                                    <CollectionRow key={c.id} collection={c} />
                                ))}
                            </ul>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
})

export default Collections
