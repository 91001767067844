import React, { useState, useContext } from "react"
import { StarIcon, ChevronRightIcon, TagIcon } from "@heroicons/react/24/outline"
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid"
import { AuthContext } from "../context/AuthContext"
import { Link } from "../stores/LinksStore"
import { useStores } from "../stores/RootStore"
import { NavLink } from "react-router-dom"
import { observer } from "mobx-react-lite"


interface Props {
    link: Link
    showCollectionName: boolean
    showFavoriteStatus: boolean
}

const formatDate = (dateString: string) => {
    const options = {
        month: "short",
        day: "numeric",
    } as Intl.DateTimeFormatOptions
    return new Date(dateString).toLocaleDateString("en-us", options)
}

const LinkThinView = observer(({ link, showCollectionName, showFavoriteStatus }: Props) => {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { linksStore, collectionsStore, visitsStore } = useStores()

    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    const handleVisit = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation()
        visitsStore.addVisit(link.id, authToken)
    }

    return (
        <div className="lg:hover:bg-content-highlight-dark active:bg-content-highlight-dark font-medium border-border-dark border-b text-sm group h-11 items-center flex flex-1 flex-row gap-1.5 py-1 px-3 lg:px-6">
            <div className="flex-none border-2 border-secondary-accent-dark rounded-full h-3 w-3 bg-primary-accent-dark"></div>
            <div className="pl-1 lg:w-20 lg:grow h-5 flex flex-row">
                <p className="line-clamp-1">{link.title}</p>
                <a className="inline-block lg:hidden group-hover:inline-block text-secondary-color-dark hover:text-primary-color-dark rounded-md hover:bg-border-dark ml-2"
                    target="_blank"
                    rel="external"
                    href={link?.url}
                    title="Go to Link"
                    onClick={handleVisit}>
                    <ChevronRightIcon className="h-5 w-5" />
                </a>
            </div>

            <div className="ml-auto">
                <div className="flex flex-row items-center">
                    {link?.tags?.length > 0 && (
                        <div className="hidden h-6 md:flex gap-1 tags mr-2 items-center">
                            {link?.tags?.map((tag: string) => (
                                // <NavLink className="" to={`/links/tag/${tag.}`} >
                                <div key={tag} className="flex h-auto pl-1 pr-2 py-0.5 text-secondary-special-dark rounded-xl border border-secondary-special-dark text-xs">
                                    <TagIcon className="h-4 w-4 mr-0.5" />
                                    <span className="h-4">{tag}</span>
                                </div>
                                // </NavLink>
                            ))}
                        </div>
                    )}

                    {showCollectionName && (
                        link?.collectionId && (
                            <NavLink className="h-auto" to={`/links/collection/${link.collectionId}`} onClick={(e) => e.stopPropagation()} >
                                <div className="hidden md:flex text-primary-special-dark h-6 py-0.5 px-2 border rounded-xl border-primary-special-dark text-xs items-center mr-2">
                                    <span className="h-4">{collectionsStore.getCollectionName(link.collectionId)}</span>
                                </div>
                            </NavLink>
                        )
                    )}
                    <p className="m-auto hidden whitespace-nowrap text-xs text-tertiery-color-dark md:block h-4">
                        {formatDate(link.createdAt)}
                    </p>
                    {showFavoriteStatus && (
                        <button
                            className="text-secondary-color-dark ml-2 hover:text-primary-color-dark"
                            disabled={isUpdating}
                            data-umami-event="favorite-link-button"
                            onClick={async (e) => {
                                setIsUpdating(true)
                                e.stopPropagation()
                                await linksStore.toggleFavorite(link, authToken)
                                setIsUpdating(false)
                            }}
                        >
                            {isUpdating ? (
                                <div className="h-auto">
                                    <svg className="animate-spin h-5 w-5 text-primary-color-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                            ) : (
                                link.favorite ? (
                                    <StarIconSolid className="h-5 w-5 text-primary-color-dark" />
                                ) : (
                                    <StarIcon className="h-5 w-5" />
                                )
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div >
    )
})

export default LinkThinView
