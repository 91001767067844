import { Collection } from "../stores/CollectionsStore"
import { NavLink } from "react-router-dom"
import { FolderIcon, PencilIcon } from "@heroicons/react/24/outline"
import { useState, useRef, useContext } from "react"
import { useStores } from "../stores/RootStore"
import { AuthContext } from "../context/AuthContext"

interface Props {
    collection: Collection
}

const CollectionRow = ({ collection }: Props) => {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { collectionsStore } = useStores()

    let [isEditable, setIsEditable] = useState<boolean>(false)

    let editableDivRef = useRef<HTMLParagraphElement>(null)

    const updateCollection = (name: string) => {
        collectionsStore.updateCollection(collection, name, authToken)
    }

    const handleEdit = () => {
        setIsEditable(true)

        // Focus and move the cursor to the end
        setTimeout(() => {
            const editableDiv = editableDivRef.current
            if (editableDiv) {
                editableDiv.focus()
                const range = document.createRange()
                const selection = window.getSelection()

                range.selectNodeContents(editableDiv)
                range.collapse(false) // Move to the end
                selection?.removeAllRanges()
                selection?.addRange(range)
            }
        }, 0)
    }

    return (
        <li key={collection.id} className="group h-9">
            <NavLink
                className={({
                    isActive,
                }) =>
                    isActive
                        ? "btn-navigation-active has-editable:border-blue-500 has-editable:border"
                        : "btn-navigation"
                }
                to={`/links/collection/${collection.id}`}
            >
                <FolderIcon className="flex-none h-5 w-5" />
                <p className="grow text-primary-color-dark outline-none"
                    ref={editableDivRef}
                    contentEditable={isEditable}
                    suppressContentEditableWarning={true}
                    onKeyDown={(e) => {
                        if (e.key == "Enter") {
                            setIsEditable(false)
                            updateCollection(editableDivRef.current?.innerText!)
                        }
                    }}
                    onBlur={(e) => {
                        setIsEditable(false)
                        updateCollection(e.target.innerText)
                    }}>
                    {collection.title}
                </p>
                {!isEditable && (
                    <button
                        className="text-transparent group-hover:text-tertiery-color-dark"
                        // onClick={() => { setIsEditable(true) }}
                        onClick={handleEdit}
                        data-umami-event="edit-collectionname-button"
                    >
                        <PencilIcon className="h-4 w-4 hover:text-primary-color-dark" />
                    </button>
                )}
            </NavLink>
        </li >
    )
}

export default CollectionRow
