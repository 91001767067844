import { useEffect, useContext, useState } from "react"
import { observer } from "mobx-react-lite"
import { AuthContext } from "../context/AuthContext"
import { useStores } from "../stores/RootStore"
import { useParams } from "react-router-dom"
import DetailsToolbar from "../components/DetailsToolbar"
import LinkInfo from "../components/LinkInfo"
import LinkMetadata from "../components/LinkMetadata"
import { ToastContainer, Zoom } from "react-toastify"
import { Logger } from "../utilities/logger"

const LinkDetails = observer(() => {
    const componentLogger = new Logger("LinkDetails")
    const { id } = useParams()

    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { linksStore, visitsStore } = useStores()

    const [detailsView, setDetailsView] = useState<string>("DETAILS")

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        componentLogger.debug("fetch link details")

        const fetchData = async () => {
            try {

                await Promise.all([
                    linksStore.fetchLinkDetails(id!, authToken),
                    visitsStore.getVisits(id!, authToken)
                ])
            } catch (error) {
                componentLogger.error("failed to link details data", error)
            }
        }

        fetchData()
    }, [linksStore, id])

    if (linksStore.isLoading) {
        componentLogger.debug("loading link details")
        return (
            <div className="details flex flex-col sm:flex-row h-full overflow-hidden">
                <div className="info sm:flex-1 h-full">
                    <DetailsToolbar detailsView={detailsView} setDetailsView={setDetailsView} link={linksStore.link} />
                </div>
            </div>
        )
    } else {
        componentLogger.debug("display link details")
    }

    if (linksStore.error) {
        componentLogger.error("error link details")
        return <div>CGI link details Error: {linksStore.error}</div>
    }

    const visitCount = visitsStore.getVisitCount(id!)

    return (
        <div className="details flex flex-col sm:flex-row h-full overflow-hidden">
            <div className="info sm:flex-1 h-full">
                <DetailsToolbar detailsView={detailsView} setDetailsView={setDetailsView} link={linksStore.link} />

                <div className="h-[calc(100%-40px)] overflow-y-auto">
                    <div className="h-auto pb-4 sm:pb-10">
                        <LinkInfo link={linksStore.link} />
                    </div>

                    {/* // The right sidepane as a bottom pane when in mobile view */}
                    <div className="metadata h-auto sm:hidden border-t border-border-dark bg-base-dark">
                        <LinkMetadata link={linksStore.link} visits={visitCount} />
                    </div>
                </div>

            </div>

            {/* // The right sidepane when in small or greater */}
            <div className="metadata hidden sm:block flex-none w-64 h-full border-l border-border-dark bg-base-dark rounded-r-lg">
                <LinkMetadata link={linksStore.link} visits={visitCount} />
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                toastClassName={() => "border-border-dark border-2 bg-alternate-dark flex p-3 rounded-md justify-between overflow-hidden cursor-pointer"}
                bodyClassName={() => "text-primary-color-dark"}
                transition={Zoom}
            />
        </div>
    )
})

export default LinkDetails



// {/* {detailsView == "DETAILS" && */}
// {/* <div className="h-[calc(100%-2.5rem)] overflow-y-auto"> */}
// <div className="h-[calc(100%-40px)] overflow-y-auto">
//     <div className="h-auto pb-4 sm:pb-10">
//         <LinkInfo link={linksStore.link} />
//     </div>

//     {/* // The right sidepane as a bottom pane when in mobile view */}
//     <div className="metadata h-auto sm:hidden border-t border-border-dark bg-base-dark">
//         <div className="mx-4 pb-10">
//             <LinkMetadata link={linksStore.link} />
//         </div>
//     </div>
// </div>
// {/* } */}
// {/* {detailsView == "WEBVIEW" &&
//     <div className="h-[calc(100%-2.5rem)]">
//         <iframe src={linksStore.link?.url} loading="lazy" className="w-full h-full"></iframe>
//     </div >
// } */}
