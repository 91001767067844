import axios from "axios"
import AppConfig from "../AppConfig"
import { Logger } from "../utilities/logger"

interface IVisit {
    id: string
    vaultId: string
    bookmarkId: string
}

interface IVisitResponse {
    data: IVisit
}

interface IVisitsCount {
    bookmarkId: string
    count: number
}

interface IVisitsCountResponse {
    data: IVisitsCount
}

const baseUrl = `${AppConfig.host}/${AppConfig.api}/v1/vaults`

export class VisitsService {
    serviceLogger = new Logger("VisitsService")

    public async addVisit(vaultId: string, bookmarkId: string, authToken: string): Promise<IVisit> {
        const url = `${baseUrl}/${vaultId}/visits`
        try {
            var payload: any = {
                "bookmarkId": bookmarkId,
            }

            const response = await axios.post<IVisitResponse>(
                url,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authToken,
                    },
                    validateStatus: () => true,
                })
            return response.data.data
        } catch (error: any) {
            this.serviceLogger.error("Error registeing visit:", error)
            throw error // Re-throw the error for the calling function to handle
        }
    }

    public async getVisitsForBookmark(vaultId: string, bookmarkId: string, authToken: string): Promise<IVisitsCount> {
        const url = `${baseUrl}/${vaultId}/visits?bookmarkId=${bookmarkId}`
        try {
            const response = await axios.get<IVisitsCountResponse>(
                url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authToken,
                    },
                    validateStatus: () => true,
                })
            return response.data.data
        } catch (error: any) {
            this.serviceLogger.error("Error registeing visit:", error)
            throw error // Re-throw the error for the calling function to handle
        }
    }
}
