import SidebarContents from "./SidebarContents"

function SidebarMobile() {

    return (
        <div className="bg-base-dark border-border-dark flex h-full flex-col rounded-r-lg border-r">
            <SidebarContents />
        </div>
    )
}

export default SidebarMobile
