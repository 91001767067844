import { JSX } from "react"
import Header from "./Header"
import Footer from "./Footer"

function Terms(): JSX.Element {
    return (
        <div className="terms h-full overflow-hidden">
            <Header />
            <main className="h-[calc(100%-56px)] overflow-y-auto">
                <div className="h-auto mx-auto max-w-7xl my-4 px-8 pb-8">
                    <div className="sm:w-8/12 mx-auto text-white">
                        <h1 className="max-w-48 mb-4 mx-auto text-center uppercase font-semibold text-2xl">Terms of service</h1>

                        <p>
                            By using Gemt ("Service"), through you browser and/or any other device or application, or any services of Gemt, you are agreeing to be bound by the following terms and conditions ("Terms of Service"). IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY, ITS AFFILIATES AND ALL USERS WHO ACCESS OUR SERVICES THROUGH YOUR ACCOUNT TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS "YOU" OR "YOUR" SHALL REFER TO SUCH ENTITY, ITS AFFILIATES AND USERS ASSOCIATED WITH IT. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.
                            <br /><br />
                            Gemt reserves the right to update and change the Terms of Service from time to time without notice. Any new features that augment or enhance the current Service, including the release of new features and resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms of Service at any time at: https://gemt.app/terms
                            <br /><br />
                            Violation of any of the terms below will result in the termination of your Account. You agree to use the Service at your own risk.
                        </p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">Basic terms</h2>

                        <ul className="list-disc px-6">
                            <li>You must be 16 years or older to use this Service.</li>
                            <li>You must provide a valid email address in order to complete the registration process.</li>
                            <li>Your login may only be used by one person - a single login shared by multiple people is not permitted. You may create separate logins for as many people as your plan allows.</li>
                            <li>You are responsible for maintaining the security of your account and password. Gemt cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
                            <li>You are solely responsible for all Content created/stored and any activity that occurs under your account (even when Content is created/updated/stored by others who share and participate with/in/as your account).</li>
                            <li>You may not use the Service for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright or trademark laws).</li>
                            <li>Gemt reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.</li>
                        </ul>

                        <h2 className="mt-6 mb-4 uppercase font-medium">Cancellation and Termination</h2>

                        <ul className="list-disc px-6">
                            <li>You are solely responsible for properly canceling your account via email to support@gemt.app</li>
                            <li>All of your Content will be deleted within 30 days from the Service upon cancellation. This information cannot be recovered once your account is cancelled.</li>
                            <li>Gemt, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Service, or any other Gemt service, for any reason at any time. Such termination of the Service will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. Gemt reserves the right to refuse service to anyone for any reason at any time.</li>
                        </ul>

                        <h2 className="mt-6 mb-4 uppercase font-medium">General terms</h2>

                        <ul className="list-disc px-6">
                            <li>Your use of the Service is at your sole risk. The service is provided on an "as is" and "as available" basis.</li>
                            <li>Technical support is only provided to account holders. Support is only available in English.</li>
                            <li>You understand that Gemt uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.</li>
                            <li>You must not modify, adapt or hack the Service so as to falsely imply that it is associated with the Service, Gemt, or any other Gemt service.</li>
                            <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by Gemt.</li>
                            <li>We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party"s intellectual property or these Terms of Service.</li>
                            <li>Verbal, physical, written or other abuse (including threats of abuse or retribution) of any Gemt customer, employee, or officer will result in immediate account termination.</li>
                            <li>Gemt does not warrant that (i) the service will meet your specific requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material obtained by you through the service will meet your expectations, and (v) any errors in the Service will be corrected.</li>
                            <li>You expressly understand and agree that Gemt shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Gemt IVS has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to the service.</li>
                            <li>Gemt does not represent nor endorses any of the contents contained in its data stores.</li>
                            <li>The failure of Gemt to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and Gemt and govern your use of the Service, superseding any prior agreements between you and Gemt (including, but not limited to, any prior versions of the Terms of Service). You agree that these Terms of Service and Your use of the Service are governed under Maltese law.</li>
                            <li>Questions about the Terms of Service should be sent to support@gemt.app.</li>
                        </ul>

                        <h2 className="mt-6 mb-4 uppercase font-medium">Governing Law</h2>

                        <p>The laws of Malta shall govern any claim relating to the services provided by Gemt.</p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">DISCLAIMER</h2>
                        <p>
                            WE PROVIDE THE SITE AND SERVICES "AS IS" AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY. WE SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, INFORMATION ACCURACY, INTEGRATION, INTEROPERABILITY OR QUIET ENJOYMENT.

                            When you use the "Service" you understand and agree that you use the services We provide at your own discretion and risk and that you will be solely responsible for any damages that arise from such use.

                            UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY KIND, OR ANY OTHER DAMAGES WHATSOEVER (HOWEVER ARISING, INCLUDING BY NEGLIGENCE), INCLUDING WITHOUT LIMITATION, DAMAGES RELATED TO USE, MISUSE, RELIANCE ON, INABILITY TO USE AND INTERRUPTION, SUSPENSION, OR TERMINATION OF THE SITE OR SERVICES, DAMAGES INCURRED THROUGH ANY LINKS PROVIDED ON THE SITE AND THE NONPERFORMANCE THEREOF AND DAMAGES RESULTING FROM LOSS OF USE, SALES, DATA, GOODWILL OR PROFITS, WHETHER OR NOT WE HAVE BEEN ADVISED OF SUCH POSSIBILITY. YOUR ONLY RIGHT WITH RESPECT TO ANY DISSATISFACTION WITH THE SERVICE SHALL BE TO TERMINATE USE OF THE SERVICE.
                        </p>

                        <p>YOU HEREBY ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTOOD IT, AND AGREE TO BE BOUND BY ITS TERMS.</p>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Terms
