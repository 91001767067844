import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { observer } from "mobx-react-lite"
import { Link } from "../stores/LinksStore"
import { useStores } from "../stores/RootStore"

interface Props {
    link: Link | null
}

const LinkInfo = observer(({ link }: Props) => {
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { linksStore } = useStores()

    return (
        <div className="link-details h-auto mx-6 sm:mx-8 md:mx-16">
            <div
                className="title text-2xl focus:outline-none text-primary-color-dark whitespace-pre-wrap mt-10 mb-3 cursor-text"
                contentEditable="true"
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                    linksStore.updateLinkTitle(
                        link!,
                        e.target.innerText,
                        authToken
                    )
                }
            >
                {link?.title}
            </div>

            <div
                className="description editable-content text-primary-color-dark text-m focus:outline-none whitespace-pre-wrap mb-8 pt-6 p-2 cursor-text"
                data-placeholder="Add a description..."
                contentEditable="true"
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                    linksStore.updateLinkDescription(
                        link!,
                        e.target.innerText,
                        authToken
                    )
                }
            >
                {link?.description}
            </div>

            <div className="my-3 w-24 flex-none text-sm text-secondary-color-dark">
                Notes
            </div>
            <div
                className="notes editable-content mt-3 p-6 w-full break-all whitespace-pre-wrap text-primary-alternative-dark focus:outline-none bg-alternate-dark border border-border-dark rounded-lg mb-8 cursor-text"
                data-placeholder="Add a note..."
                contentEditable="true"
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                    linksStore.updateLinkNotes(
                        link!,
                        e.target.innerText,
                        authToken
                    )
                }
            >
                {link?.notes}
            </div>

            {link?.archived && (
                <div className="my-3 py-4 bg-quaternary-special-dark/10 text-center flex-none text-sm text-quaternary-special-dark border border-quaternary-special-dark rounded-lg">
                    archived
                </div>
            )}

        </div>
    )
})

export default LinkInfo
