import { observer } from "mobx-react"
import { Link } from "../stores/LinksStore"
import { Tag } from "../stores/TagsStore"
import { AuthContext } from "../context/AuthContext"
import { Listbox } from "@headlessui/react"
import { useState, useEffect, useContext } from "react"
import { CheckIcon } from "@heroicons/react/24/solid"
import { BookOpenIcon, EyeIcon, FolderIcon, PlusIcon } from "@heroicons/react/24/outline"
import { Collection } from "../stores/CollectionsStore"
import { useStores } from "../stores/RootStore"
import { CalendarIcon, PlusCircleIcon, CheckCircleIcon, GlobeAltIcon, LockClosedIcon, TagIcon } from "@heroicons/react/24/outline"
import AppConfig from "../AppConfig"
import CreateTagDialog from "./CreateTagDialog"
import { Logger } from "../utilities/logger"


interface Props {
    link: Link | null
    visits: number | null
}

interface ICollectionInfo {
    id: string,
    title: string
}

const formatDate = (dateString: string | undefined) => {
    if (dateString == undefined) {
        return
    }

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        // hour: "numeric",
        // minute: "numeric",
        // second: "numeric",
    } as Intl.DateTimeFormatOptions
    return new Date(dateString).toLocaleDateString("en-us", options)
}

const LinkMetadata = observer(({ link, visits }: Props) => {
    const componentLogger = new Logger("LinkMetadata")
    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { collectionsStore, linksStore, tagsStore } = useStores()

    // Tags
    const [selectedTags, setSelectedTags] = useState<Tag[]>([])
    let [isCreateTagDialogOpen, setCreateTagDialogOpen] = useState<boolean>(false)

    // Collections
    const [selectedCollection, setSelectedCollection] = useState<ICollectionInfo | null>()
    const noCollection = { id: AppConfig.zeroUUID, title: "No Collection" }

    useEffect(() => {
        const current = collectionsStore.collections.find(
            (ele: ICollectionInfo) => ele.id === (link?.collectionId ?? null)
        )
        setSelectedCollection(current)

        const tags = tagsStore.tags.filter(tag => link?.tags.includes(tag.name))
        setSelectedTags(tags)
    }, [link])

    function onChangeTag(change: Tag[]): void {
        if (link != null) {
            // componentLogger.debug(JSON.stringify(change))
            if (isAdding(selectedTags, change)) {
                const tagToAdd = isSignificantTag(change, selectedTags)
                componentLogger.debug("Adding tag:", tagToAdd.name)
                linksStore.addTagsToLink([tagToAdd], link.id, authToken)
                tagsStore.incrementLocalMatchedCount(tagToAdd)

            } else {
                const tagToRemove = isSignificantTag(selectedTags, change)
                componentLogger.debug("Removing tag:", tagToRemove.name)
                linksStore.removeTagsFromLink([tagToRemove], link.id, authToken)
                tagsStore.decrementLocalMatchedCount(tagToRemove)
            }

            setSelectedTags(change)
        }
    }

    function isAdding(existingTags: Tag[], newTags: Tag[]): boolean {
        return newTags.length > existingTags.length
    }

    function isSignificantTag(longer: Tag[], shorter: Tag[]): Tag {
        const difference = longer.filter(obj1 =>
            !shorter.some(obj2 => obj2.id === obj1.id)
        )
        return difference[0]
    }

    function onChangeCollection(collection: Collection): void {
        if (link != null) {
            if (collection.id == AppConfig.zeroUUID) {
                setSelectedCollection(null)
                linksStore.updateLinkCollection(link, collection.id, authToken)
            } else {
                setSelectedCollection(collection)
                linksStore.updateLinkCollection(link, collection.id, authToken)
            }
        }
    }

    return (
        <div className="mx-4 pb-10">
            <div className="link-metadata h-auto text-secondary-color-dark text-sm ">
                <div className="text-sm pt-3 pb-3 sm:pb-6">
                    Properties
                </div>


                <div className="flex flex-col">
                    <div className="mb-2 h-auto">
                        <div className="flex gap-2 items-center">
                            <div><GlobeAltIcon className="w-5 h-5" /></div>
                            <div className="break-all text-tertiary-special-dark">
                                {link?.host}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2  h-auto">
                        <div className="flex gap-2 items-center">
                            <div><LockClosedIcon className="w-5 h-5" /></div>
                            <div className="text-xs">
                                {link?.scheme}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2  h-auto">
                        <div className="flex gap-2 items-center">
                            <div><BookOpenIcon className="w-5 h-5" /></div>
                            <div className="text-xs">
                                {link?.linkType !== "" ? link?.linkType : "not set"}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2">
                        <div className="flex gap-2 items-center">
                            <div><CalendarIcon className="w-5 h-5" /></div>
                            <div className="text-xs">
                                {formatDate(link?.createdAt)}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2">
                        <div className="flex gap-2 items-center">
                            <div><EyeIcon className="w-5 h-5" /></div>
                            <div className="text-xs">
                                {visits ?? "0"}
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="flex flex-col">
                            <div className="h-auto mt-3 mb-1 w-24 flex-none text-sm text-secondary-color-dark">
                                Indexed
                            </div>
                            <div className="px-2 py-1 text-xs">
                                <span className="flex gap-2 items-center"><CheckCircleIcon className="w-5 h-5" /> {formatDate(link?.indexedAt)} </span>
                            </div>

                        </div>
                    </div>

                    <div className="mb-2">
                        <div className="flex flex-col">
                            <div className="h-auto mt-3 w-24 flex-none text-sm text-secondary-color-dark my-1">
                                Tags
                            </div>
                            <div className="">
                                <Listbox value={selectedTags} by="id" onChange={onChangeTag} multiple>
                                    <div className="flex flex-wrap w-2/3 gap-1">
                                        {selectedTags.map((tag) =>
                                            <div key={tag.id}
                                                className="text-secondary-special-dark inline-flex h-6 px-2 items-center">
                                                <span className="">{tag.name}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative">
                                        <Listbox.Button className="hover:bg-sidebar-highlight-dark flex items-center justify-center gap-1.5 rounded px-2 py-1 text-xs focus:outline-none">
                                            <span className="flex truncate gap-2 items-center">
                                                <PlusCircleIcon className="h-5 w-5" /> Add a tag
                                            </span>
                                        </Listbox.Button>
                                        <Listbox.Options className="h-auto bg-sidebar-highlight-dark border-border-dark absolute left-0 z-10 mt-1 w-44 origin-top-right rounded-md border py-1 px-1 shadow-lg focus:outline-none">
                                            <button
                                                onClick={() => setCreateTagDialogOpen(true)}
                                                data-umami-event="add-collection-button"
                                                className="mb-1 text-primary-color-dark border border-tertiery-color-dark bg-tertiery-color-dark rounded py-0.5 w-full px-2 flex gap-1.5 text-sm hover:text-primary-color-dark"
                                            >
                                                <PlusIcon className="h-5 w-5" />
                                                <p className="">Create new tag</p>
                                            </button>
                                            <hr className="border-t border-tertiery-color-dark my-1" />
                                            {tagsStore.tags.map((tag) => (
                                                <Listbox.Option
                                                    key={tag.id}
                                                    className={({ active }) =>
                                                        `relative flex w-full cursor-default gap-2 px-2 py-1 text-sm ${active
                                                            ? "text-primary-color-dark bg-indigo-600 rounded"
                                                            : "text-secondary-color-dark"
                                                        }`
                                                    }
                                                    value={tag}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span>
                                                                <TagIcon className="h-5 w-5" />
                                                            </span>

                                                            <span>
                                                                {tag.name}
                                                            </span>

                                                            {selected && (
                                                                <span className="ml-auto">
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>
                            </div>
                        </div>

                    </div>

                    <CreateTagDialog isCreateTagDialogOpen={isCreateTagDialogOpen} setCreateTagDialogOpen={setCreateTagDialogOpen} />

                    <div className="mb-2">
                        <div className="flex flex-col">
                            <div className="h-auto mt-3 mb-1 w-24 flex-none text-sm text-secondary-color-dark">
                                Collection
                            </div>
                            <div className="my-auto">
                                <Listbox value={selectedCollection} by="id" onChange={onChangeCollection}>
                                    <div className="relative">
                                        <Listbox.Button className="hover:bg-sidebar-highlight-dark flex items-center justify-center gap-1.5 rounded px-2 py-1 text-sm focus:outline-none">
                                            <span className={`${selectedCollection ? "text-primary-special-dark" : ""} flex truncate gap-2 items-center`}>
                                                {/* <FolderIcon className="h-5 w-5" /> */}
                                                {selectedCollection ? selectedCollection?.title : "Add to collection"}
                                            </span>
                                        </Listbox.Button>

                                        <Listbox.Options className="h-auto bg-sidebar-highlight-dark border-border-dark absolute left-0 z-10 mt-1 w-44 origin-top-right rounded-md border py-1 px-1 shadow-lg focus:outline-none">
                                            <Listbox.Option
                                                key={noCollection.id}
                                                className="relative flex w-full cursor-default gap-2 px-2 py-1 text-sm text-secondary-color-dark hover:text-primary-color-dark hover:bg-primary-accent-dark hover:rounded"
                                                value={noCollection}>
                                                <>
                                                    <span><FolderIcon className="h-5 w-5" /></span>
                                                    <span>No Collection</span>
                                                </>
                                            </Listbox.Option>
                                            {collectionsStore.collections.map((item) => (
                                                <Listbox.Option
                                                    key={item.id}
                                                    className={({ active }) =>
                                                        `relative flex w-full cursor-default gap-2 px-2 py-1 text-sm ${active
                                                            ? "text-primary-color-dark bg-indigo-600 rounded"
                                                            : "text-secondary-color-dark"
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span>
                                                                <FolderIcon className="h-5 w-5" />
                                                            </span>

                                                            <span>
                                                                {item.title}
                                                            </span>

                                                            {selected && (
                                                                <span className="ml-auto">
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>
                            </div>
                        </div>

                    </div>

                </div>


                {/* <div className="mb-2 flex">
                <div className="my-3 w-24 flex-none">URL</div>
                <div className="mt-3 break-all text-orange-400">
                    {link?.url}
                </div>
            </div> */}

            </div>
        </div>
    )
})

export default LinkMetadata
