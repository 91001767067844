import { ArchiveBoxIcon, CircleStackIcon, StarIcon, InboxIcon } from "@heroicons/react/24/outline"
import { NavLink } from "react-router-dom"

function Categories() {
    return (
        <div className="categories">
            <ul className="list-none">
                <li className="h-9" key="all"><NavLink className={({ isActive }) => (isActive ? "btn-navigation-active" : "btn-navigation")} to="/links/all"><InboxIcon className="h-5 w-5" /><p className="text-primary-color-dark">All links</p></NavLink></li>
                <li className="h-9" key="favorite"><NavLink className={({ isActive }) => (isActive ? "btn-navigation-active" : "btn-navigation")} to="/links/favorites"><StarIcon className="h-5 w-5" /><p className="text-primary-color-dark">Favorites</p></NavLink></li>
                <li className="h-9" key="uncategorized"><NavLink className={({ isActive }) => (isActive ? "btn-navigation-active" : "btn-navigation")} to="/links/uncategorized"><CircleStackIcon className="h-5 w-5" /><p className="text-primary-color-dark">Uncategorized</p></NavLink></li>
                <li className="h-9" key="archived"><NavLink className={({ isActive }) => (isActive ? "btn-navigation-active" : "btn-navigation")} to="/links/archived"><ArchiveBoxIcon className="h-5 w-5" /><p className="text-primary-color-dark">Archived</p></NavLink></li>
            </ul>
        </div>
    )
}

export default Categories
