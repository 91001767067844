import { JSX } from "react"
import { NavLink } from "react-router-dom"

function Home(): JSX.Element {
    const currentYear = new Date().getFullYear()

    return (
        <div className="home h-full flex flex-col justify-between">
            <header className="h-14">
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8" aria-label="Global">
                    <div className="flex flex-1">
                    </div>
                    <div className="flex flex-1 justify-end gap-4 items-center">
                        <NavLink
                            className="font-medium text-white"
                            to="/login"
                        >
                            Login
                        </NavLink>

                        {/*<NavLink
                            className="font-medium text-white border border-white rounded-lg px-2 py-1"
                            to="/register"
                        >
                            Register
                        </NavLink> */}
                    </div>
                </nav>
            </header>
            <div className="h-auto flex flex-col items-center px-6 py-8">
                <div className="">
                    <img src="/gemt-logo-neu.png" id="logo-main" alt="gemt" />
                </div>
                <div className="w-full mt-8 mb-2">
                    <div className="space-y-2 p-6">
                        <div className="h-auto text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-white">The easiest way to save your links</h1>
                            <p className="text-lg leading-8 text-gray-400">Save all your links in one place, access them from anywhere.</p>
                        </div>
                    </div>
                </div>
                <div className="flex gap-4 text-sm text-gray-500 mt-8">

                    <NavLink
                        className="font-medium hover:text-white"
                        to="/terms"
                    >Terms of Service</NavLink>

                    <NavLink
                        className="font-medium hover:text-white"
                        to="/privacy"
                    >Privacy Policy</NavLink>

                </div>

            </div>

            <div className="h-auto flex flex-col items-center">
                <div className="text-gray-600 text-xs mb-6 pt-2">© gemt:: {currentYear}</div>
            </div>
        </div >
    )
}

export default Home
