type LogLevel = "debug" | "info" | "warn" | "error";

export class Logger {
    private context: string
    private static globalLevel: LogLevel = process.env.NODE_ENV === "production" ? "warn" : "debug"

    constructor(context: string = "Default") {
        this.context = context
    }

    private static levels: LogLevel[] = ["debug", "info", "warn", "error"]

    private shouldLog(level: LogLevel): boolean {
        return Logger.levels.indexOf(level) >= Logger.levels.indexOf(Logger.globalLevel)
    }

    private log(level: LogLevel, ...messages: any[]): void {
        if (this.shouldLog(level)) {
            console.log(`[${new Date().toISOString()}] [${this.context}] ${level.toUpperCase()}:`, ...messages)
        }
    }

    debug(...messages: any[]): void {
        this.log("debug", ...messages)
    }

    info(...messages: any[]): void {
        this.log("info", ...messages)
    }

    warn(...messages: any[]): void {
        this.log("warn", ...messages)
    }

    error(...messages: any[]): void {
        this.log("error", ...messages)
    }
}

// Default instance
export const logger = new Logger("App")
