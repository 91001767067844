import { observer } from "mobx-react"
import React, { useContext, useState, Dispatch, SetStateAction } from "react"
import MobileSidebarButton from "./MobileSidebarButton"
import { ArchiveBoxIcon, ClipboardIcon, EyeIcon, StarIcon } from "@heroicons/react/24/outline"
import { StarIcon as StarIconSolid, } from "@heroicons/react/24/solid"
import { ArchiveBoxIcon as ArchiveBoxIconSolid, } from "@heroicons/react/24/solid"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { AuthContext } from "../context/AuthContext"
import { Link } from "../stores/LinksStore"
import { useStores } from "../stores/RootStore"
// import LinksDetailsTabControl from "./LinkDetailsTabControl"

interface Props {
    link: Link | null
    detailsView: string
    setDetailsView: Dispatch<SetStateAction<string>>
}

export async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand("copy", true, text)
    }
}

const DetailsToolbar = observer(({ link }: Props) => {

    const toastCopyId = link?.url

    const authContext = useContext(AuthContext)
    const authToken = authContext.getAuthToken()

    // here you can access all of the stores registered on the root store
    const { linksStore, visitsStore } = useStores()
    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    const handleCopyClick = () => {
        copyTextToClipboard(link?.url ?? "")
        toast("Copied to clipboard", {
            toastId: toastCopyId,
            // style: {
            //     backgroundColor: "orange", // Replace with your desired color
            //     color: "red", // Adjust text color for contrast
            // },
        })
    }

    const handleVisit = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation()
        visitsStore.addVisit(link!!.id, authToken)
    }

    return (
        <div className="toolbar-details h-10 py-3 border-border-dark flex justify-between items-center gap-4 border-b px-3 text-sm text-primary-color-dark">
            <div className="info flex gap-2 items-center">
                <MobileSidebarButton />
                <p className="font-medium">Details</p>
            </div>

            {/* <LinksDetailsTabControl detailsView={detailsView} setDetailsView={setDetailsView} /> */}

            <div className="controls h-auto flex items-center gap-4 text-secondary-color-dark">
                <button className="hover:text-primary-color-dark flex"
                    disabled={isUpdating}
                    data-umami-event="details-favorite-link-button"
                    onClick={async (e) => {
                        setIsUpdating(true)
                        e.stopPropagation()
                        if (link != null) {
                            await linksStore.toggleFavorite(link, authToken)
                        }
                        setIsUpdating(false)
                    }}>
                    {isUpdating ? (
                        <div className="h-auto">
                            <svg className="animate-spin h-5 w-5 text-primary-color-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    ) : (
                        link?.favorite ? (
                            <StarIconSolid className="h-5 w-5 text-primary-color-dark" />
                        ) : (
                            <StarIcon className="h-5 w-5" />
                        )
                    )}
                </button>

                <a className="hover:text-primary-color-dark flex"
                    target="_blank"
                    rel="external"
                    href={link?.url ?? ""}
                    title="Go to Link"
                    onClick={handleVisit}>
                    <EyeIcon className="h-5 w-5" />
                </a>

                <button className="hover:text-primary-color-dark flex"
                    onClick={handleCopyClick}
                    title="Copy">
                    <ClipboardIcon className="h-5 w-5" />
                </button>

                <button className="hover:text-primary-color-dark flex"
                    onClick={async (e) => {
                        e.stopPropagation()
                        if (link != null) {
                            await linksStore.toggleArchived(link, authToken)
                        }
                    }}
                    title="Archive">
                    {link?.archived ? (
                        <ArchiveBoxIconSolid className="h-5 w-5 text-primary-color-dark" />
                    ) : (
                        <ArchiveBoxIcon className="h-5 w-5" />
                    )}
                </button>
            </div>
        </div>
    )
})

export default DetailsToolbar
