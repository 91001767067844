import { makeAutoObservable, runInAction } from "mobx"
import { VisitsService } from "../services/VisitsService"
import RootStore from "./RootStore"
import { Logger } from "../utilities/logger"

export class VisitCount {
    bookmarkId: string
    count: number

    constructor(bookmarkId: string, count: number) {
        makeAutoObservable(this)
        this.bookmarkId = bookmarkId
        this.count = count
    }
}

class VisitsStore {
    rootStore: RootStore
    visits: Map<string, VisitCount> = new Map()
    isLoading: boolean = false
    error: any = null
    visitsService: VisitsService
    storeLogger = new Logger("VisitsStore")

    // `this` from rootstore passed to the constructor and we can
    // assign it to a variable accessible in this class called
    // `rootStore`. Therefore, we can access other store like
    // useStore for e.g (this.rootStore.userStore)
    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            visitsService: false
        })

        this.rootStore = rootStore
        this.visitsService = new VisitsService()
    }

    getVisitCount(bookmarkId: string): number {
        return this.visits.get(bookmarkId)?.count ?? 0
    }

    // Action to add a bookmark (optimistic)
    addVisit = async (bookmarkId: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        // Optimistically update the store
        var visitCount = this.visits.get(bookmarkId)
        if (visitCount) {
            visitCount.count += 1
        }

        // Propagate the change to the backend
        this.syncWithBackend(() =>
            this.visitsService.addVisit(vaultId, bookmarkId, authToken)
        ).catch(() => {
            // Rollback if the backend call fails
            this.storeLogger.error("Error adding visit")
        })
    }

    getVisits = async (bookmarkId: string, authToken?: string): Promise<number> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return 0
        }

        this.isLoading = true
        this.error = null

        try {
            const response = await this.visitsService.getVisitsForBookmark(vaultId, bookmarkId, authToken)
            runInAction(() => {
                this.visits.set(bookmarkId, new VisitCount(bookmarkId, response.count))
                this.isLoading = false
            })

            return response.count
        } catch(error: any) {
            runInAction(() => {
                this.error = "Failed to fetch bookmark visits"
                this.isLoading = false
            })
            this.storeLogger.error("Error getting visits")

            return 0
        }
    }

    // Helper for backend synchronization with error handling
    syncWithBackend = async (apiCall: any): Promise<void> => {
        try {
            await apiCall() // Perform the API call
            this.error = null // Clear any previous errors
        } catch (err) {
            runInAction(() => {
                this.error = "Failed to sync with backend"
            })
            throw err // Rethrow to handle rollback in caller
        }
    }

}

export default VisitsStore
