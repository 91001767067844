import { observer } from "mobx-react-lite"
import { useEffect, useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"
import LinksCollection from "../components/LinksCollection"
import LinksToolbar from "../components/LinksToolbar"
import { useStores } from "../stores/RootStore"
import { Logger } from "../utilities/logger"


const Category = observer(() => {
    const componentLogger = new Logger("Category")
    const location = useLocation()

    const tokens = location.pathname.split("/")
    const category = tokens[tokens.length - 1]

    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { linksStore } = useStores()

    const [linksView, setLinksView] = useState<string>(localStorage.getItem("setting.links.view") ?? "THIN")

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        componentLogger.debug("fetch links for category", category)
        linksStore.fetchLinksForCategory(category, authToken)
    }, [linksStore, category])

    useEffect(() => {
        localStorage.setItem("setting.links.view", linksView)
    }, [linksView])

    if (linksStore.isLoading) {
        componentLogger.debug("loading links for category")
    } else {
        componentLogger.debug("display links for category")
    }

    if (linksStore.error) {
        componentLogger.error("error links for category")
        return <div>CGI category Error: {linksStore.error}</div>
    }

    const categoryIcon = getIconName(category)

    return (
        <div className="category flex flex-col h-full text-white overflow-hidden">
            <LinksToolbar
                iconName={categoryIcon}
                title={category}
                count={linksStore.links.length}
                linksView={linksView}
                setLinksView={setLinksView}
            />

            <LinksCollection
                links={linksStore.links}
                showCollectionName={true}
                showFavoriteStatus={true}
                linksView={linksView}
                isLoading={linksStore.isLoading}
            />
        </div>
    )
})

export default Category

const getIconName = (name: string): string => {
    switch (name) {
        case "all":
            return "InboxIcon"
        case "favorites":
            return "StarIcon"
        case "uncategorized":
            return "CircleStackIcon"
        case "archived":
            return "ArchiveBoxIcon"
        default:
            return ""
    }
}
