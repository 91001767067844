import CollectionsStore from "./CollectionsStore"
import LinksStore from "./LinksStore"
import { createContext, useContext } from "react"
import TagsStore from "./TagsStore"
import VaultsStore from "./VaultsStore"
import SearchStore from "./SearchStore"
import VisitsStore from "./VisitsStore"

export default class RootStore {
    vaultsStore: VaultsStore
    collectionsStore: CollectionsStore
    tagsStore: TagsStore
    linksStore: LinksStore
    visitsStore: VisitsStore
    searchStore: SearchStore

    constructor() {
        this.vaultsStore = new VaultsStore(this)
        this.collectionsStore = new CollectionsStore(this)
        this.tagsStore = new TagsStore(this)
        this.linksStore = new LinksStore(this)
        this.visitsStore = new VisitsStore(this)
        this.searchStore = new SearchStore(this)
    }
}

const StoresContext = createContext(new RootStore())

// this will be the function available for the app to connect to the stores
export const useStores = () => useContext(StoresContext)
