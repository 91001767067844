import { useParams, useNavigate } from "react-router-dom"
import { useContext, useEffect } from "react"
import { AuthContext } from "../context/AuthContext"
import { BounceLoader } from "react-spinners"

function Auth() {
    let { email, token } = useParams()

    const auth = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        authorizeUser()
    }, [])

    async function authorizeUser() {
        const success = await auth.authorize(token!, email)
        if (success === true) {
            navigate("/links/all")
        } else {
            navigate("/login")
        }
    }

    return (
        <div className="grid h-screen place-items-center"><BounceLoader color="#4F46E5" /></div>
    )
}

export default Auth
