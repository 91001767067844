import { Bars3Icon } from "@heroicons/react/24/outline"
import { Dialog } from "@headlessui/react"
import { useState } from "react"
import SidebarMobile from "./SidebarMobile"

function MobileSidebarButton() {
    let [isOpen, setIsOpen] = useState<boolean>(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <div className="flex-none lg:hidden">
            <button
                onClick={openModal}
                className="text-secondary-color-dark flex gap-1.5 text-sm hover:text-primary-color-dark"
            >
                <Bars3Icon className="h-6 w-6" />
            </button>

            <Dialog
                open={isOpen}
                onClose={() => closeModal()}
                className="relative z-30"
            >
                {/* The backdrop, rendered as a fixed sibling to the panel container */}
                <div
                    className="fixed inset-0"
                    aria-hidden="true"
                />

                <div className="fixed inset-0 flex w-60">
                    <Dialog.Panel className="w-full transform overflow-hidden shadow-xl transition-all">
                        <SidebarMobile />
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>
    )
}

export default MobileSidebarButton
