import { CheckIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid"
import { Listbox } from "@headlessui/react"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useStores } from "../stores/RootStore"
import { Sorting } from "../stores/LinksStore"

type SortingItem = {
    id: Sorting
    name: string
}

const items: SortingItem[] = [
    { id: Sorting.TitleAsc, name: "By title (A-Z)" },
    { id: Sorting.TitleDesc, name: "By title (Z-A)" },
    { id: Sorting.DateAsc, name: "By date \u{2191}" },
    { id: Sorting.DateDesc, name: "By date \u{2193}" },
    { id: Sorting.SiteAsc, name: "By site (A-Z)" },
    { id: Sorting.SiteDesc, name: "By site (Z-A)" },
]

const SortLinksControl = observer(() => {
    // here you can access all of the stores registered on the root store
    const { linksStore } = useStores()

    const [selected, setSelected] = useState<SortingItem>(items[3])

    async function sortChange(item: SortingItem) {
        setSelected(item)
        await linksStore.sortLinksBy(item.id)
    }

    return (
        <Listbox value={selected} onChange={sortChange}>
            <div className="relative">
                <Listbox.Button className="text-secondary-color-dark flex items-center w-full justify-center gap-1.5 text-xs hover:text-white focus:outline-none">
                    <AdjustmentsHorizontalIcon className=" h-4 w-4" />
                    <span className="block truncate">
                        {selected.name}
                    </span>
                </Listbox.Button>

                <Listbox.Options className="h-auto bg-base-dark border-border-dark absolute right-0 z-10 mt-1 w-44 origin-top-right rounded-md border py-1 shadow-lg focus:outline-none">
                    {items.map((item, itemIdx) => (
                        <Listbox.Option
                            key={itemIdx}
                            className={({ active }) =>
                                `relative flex w-full cursor-default gap-1 px-1.5 py-1 text-sm ${active
                                    ? "text-primary-color-dark"
                                    : "text-secondary-color-dark"
                                }`
                            }
                            value={item}
                        >
                            {({ selected }) => (
                                <>
                                    {selected ? (
                                        <span className="text-indigo-600">
                                            <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    ) : (
                                        <span className="h-5 w-5"></span>
                                    )}

                                    <span
                                        className={`${selected ? "text-primary-color-dark" : ""}`}
                                    >
                                        {item.name}
                                    </span>
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    )
})

export default SortLinksControl
