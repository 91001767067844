import { JSX } from "react"
import Header from "./Header"

function Support(): JSX.Element {
    return (
        <div className="support  h-full overflow-hidden">
            <Header />
            <main className="h-[calc(100%-56px)] overflow-y-auto">
                <div className="mx-20 my-10 px-8">
                    <div className="w-full">
                        <h1 className="max-w-48 mb-4 mx-auto">Support</h1>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Support
