
function NoResults() {
    return (
        <div className="noresults">
            <div className="mx-6 h-auto">
                <div className="py-20 lg:py-32">
                    <h2 className="font-semibold text-center text-[18px] text-secondary-color-dark">Nothing found in all your links</h2>
                    <p className="text-center text-secondary-color-dark">Your search did not return any results, try again.</p>
                </div>
            </div>
        </div>
    )
}

export default NoResults
