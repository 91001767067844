

function Footer() {
    const currentYear = new Date().getFullYear()

    return (
        <footer className="h-auto">
            <div className="h-auto mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8">
                <div className="text-gray-600 text-xs"><img src="/gemt-logo-neu.png" id="logo-footer" alt="gemt" /></div>
                <div className="text-gray-600 text-xs">© gemt:: {currentYear}</div>
            </div>
        </footer>
    )
}

export default Footer
