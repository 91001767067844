import { useEffect, useContext, useState } from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"
import LinksCollection from "../components/LinksCollection"
import LinksToolbar from "../components/LinksToolbar"
import { useStores } from "../stores/RootStore"
import { Logger } from "../utilities/logger"

const Tag = observer(() => {
    const componentLogger = new Logger("Tag")
    const { id } = useParams()

    const authContext = useContext(AuthContext)
    // here you can access all of the stores registered on the root store
    const { linksStore, tagsStore } = useStores()

    const [linksView, setLinksView] = useState<string>(localStorage.getItem("setting.links.view") ?? "THIN")

    useEffect(() => {
        const authToken = authContext.getAuthToken()
        componentLogger.debug("fetch links for tags")
        linksStore.fetchLinksForTag(id!, authToken)
    }, [linksStore, id])

    useEffect(() => {
        localStorage.setItem("setting.links.view", linksView)
    }, [linksView])

    if (linksStore.isLoading) {
        componentLogger.debug("loading links for tag")
    } else {
        componentLogger.debug("display links for tag")
    }

    if (linksStore.error) {
        componentLogger.error("error links for tag")
        return <div>CGI tag Error: {tagsStore.error}</div>
    }

    return (
        <div className="tag flex flex-col h-full text-white overflow-hidden">

            <LinksToolbar
                iconName="TagIcon"
                title={tagsStore.getTagName(id!)}
                count={linksStore.links.length}
                linksView={linksView}
                setLinksView={setLinksView}
            />

            <LinksCollection
                links={linksStore.links}
                showCollectionName={false}
                showFavoriteStatus={true}
                linksView={linksView}
                isLoading={linksStore.isLoading}
            />

        </div>
    )
})

export default Tag
