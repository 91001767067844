import RowsLoader from "../components/RowsLoader"
import LinkRow from "./LinkRow"
import { Link } from "../stores/LinksStore"
import { observer } from "mobx-react-lite"
import NoResults from "./NoResults"

interface CollectionProps {
    links: Link[]
    showCollectionName: boolean
    showFavoriteStatus: boolean
    linksView: string
    isLoading: boolean
}

const LinksResults = observer(({
    links,
    showCollectionName,
    showFavoriteStatus,
    linksView,
    isLoading,
}: CollectionProps) => {

    if (isLoading == true) {
        return <RowsLoader />
    }

    if (links?.length === 0) {
        return <NoResults />
    }

    return (
        <div className="flex-1 h-[calc(100%-72px)] text-white overflow-hidden">

            <div className="h-full overflow-y-auto">
                <ul className="list-none">
                    {links?.map((link) => (
                        <LinkRow
                            key={link.id}
                            link={link}
                            showCollectionName={showCollectionName}
                            showFavoriteStatus={showFavoriteStatus}
                            linksView={linksView}
                        />
                    ))}
                </ul>
            </div>

        </div>
    )
})

export default LinksResults
