import SortLinksControl from "./SortLinksControl"
import MobileSidebarButton from "./MobileSidebarButton"
import {
    InboxIcon,
    FolderIcon,
    TagIcon,
    MagnifyingGlassIcon,
    StarIcon,
    CircleStackIcon,
    ArchiveBoxIcon,
} from "@heroicons/react/24/outline"
import { NavLink } from "react-router-dom"
import { Dispatch, SetStateAction } from "react"
import AddLinkButton from "./AddLinkButton"


type ToolBarProps = {
    iconName: string
    title: string
    count: number
    linksView: string
    setLinksView: Dispatch<SetStateAction<string>>
}

const LinksToolbar = ({ iconName, title, count }: ToolBarProps) => {

    function capitalizeFirst(str: string | null): string {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        }

        return ""
    }

    return (
        <div className="h-20 toolbar">
            <div className="toolbar-content h-12 py-3 border-border-dark flex justify-between items-center gap-4 border-b px-3 lg:px-6 text-primary-color-dark">
                <div className="info flex gap-2 items-center text-sm">
                    <MobileSidebarButton />
                    <TitleIcon iconName={iconName} /><p className="font-medium h-5">{capitalizeFirst(title)}</p>
                </div>

                <div className="controls flex items-center gap-2 text-secondary-color-dark">
                    <AddLinkButton />

                    <div className="mx-3 h-5 w-px bg-border-dark py-1"></div>

                    <NavLink to="/links/search">
                        <MagnifyingGlassIcon className="w-5 h-5 hover:text-primary-color-dark" />
                    </NavLink>


                    {/* <EllipsisHorizontalCircleIcon className="w-5 h-5 hover:text-primary-color-dark" /> */}

                </div>
            </div>


            <div className="toolbar-info flex justify-between items-center text-primary-color-dark text-xs h-8 px-6 border-b border-border-dark">
                <p className="font-light h-4">{count} links</p>

                <div className="h-4">
                    <SortLinksControl />
                </div>
            </div>

        </div>
    )
}

export default LinksToolbar

type TitleIconProps = {
    iconName: string
    size?: number
};

const TitleIcon = ({ iconName, size = 20 }: TitleIconProps) => {
    const style = { width: size, height: size }

    switch (iconName) {
        case "InboxIcon":
            return <InboxIcon style={style} />
        case "StarIcon":
            return <StarIcon style={style} />
        case "CircleStackIcon":
            return <CircleStackIcon style={style} />
        case "ArchiveBoxIcon":
            return <ArchiveBoxIcon style={style} />
        case "FolderIcon":
            return <FolderIcon style={style} />
        case "TagIcon":
            return <TagIcon style={style} />
        default:
            return <FolderIcon style={style} />
    }
}
