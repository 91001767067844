import { NavLink } from "react-router-dom"

function Header() {
    return (
        <header className="h-14">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8" aria-label="Global">
                <div className="flex flex-1">
                    <NavLink
                        className="font-medium text-white"
                        to="/"
                    >
                        <img src="/gemt-logo-neu.png" id="logo-header" alt="gemt" />
                    </NavLink>
                </div>
                <div className="flex flex-1 justify-end gap-4 items-center">
                    <NavLink
                        className="font-medium text-white"
                        to="/login"
                    >
                        Login
                    </NavLink>

                    {/*<NavLink
                        className="font-medium text-white border border-white rounded-lg px-2 py-1"
                        to="/register"
                    >
                        Register
                    </NavLink> */}
                </div>
            </nav>
        </header>
    )
}

export default Header
