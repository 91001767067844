import { JSX } from "react"
import Header from "./Header"
import Footer from "./Footer"

function Terms(): JSX.Element {
    return (
        <div className="gdpr h-full overflow-hidden">
            <Header />
            <main className="h-[calc(100%-56px)] overflow-y-auto">
                <div className="h-auto mx-auto max-w-7xl my-4 px-8 pb-8">
                    <div className="sm:w-8/12 mx-auto text-white">
                        <h1 className="max-w-48 mb-4 mx-auto text-center uppercase font-semibold text-2xl">Privacy Policy and Data Handling at Gemt</h1>

                        <p>
                        At Gemt, we take privacy seriously and are committed to complying with the General Data Protection Regulation (GDPR). This page outlines how we handle your data and clarifies our minimal data collection practices.
                        </p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">What Data We Collect</h2>
                        <p>Gemt collects only the minimal information necessary to provide our bookmarking services:</p>
                        <ul className="list-disc px-6">
                            <li><strong>Email Address:</strong> Required for account creation and secure login.</li>
                            <li><strong>Bookmarks:</strong> URLs and metadata you choose to save using our platform.</li>
                            <li><strong>Auth Token:</strong> Used for user authentication and session management. This is strictly necessary for the functionality of the site.</li>
                            <li><strong>UI Preferences:</strong> These are user-configurable settings stored in local storage to provide a personalized experience. Examples include theme settings and layout preferences.</li>
                        </ul>
                        <p>The URLs stored in your Gemt account are considered non-personal data unless they contain information that could identify an individual.</p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">How We Use Your Data</h2>
                        <p>Your data is processed solely for the purpose of providing and improving Gemt’s bookmarking services:</p>
                        <ul className="list-disc px-6">
                            <li><strong>Email Address:</strong> Used for user authentication and account-related communication.</li>
                            <li><strong>Bookmarks:</strong> Stored securely to facilitate easy access and organization within your Gemt account.</li>
                        </ul>
                        <p></p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">Tracking and Analytics</h2>
                        <p>Gemt uses Umami for tracking user activity. Umami is an EU-hosted, GDPR-compliant, privacy-focused analytics platform. It collects anonymized data for usage analysis without using cookies or invasive tracking methods.</p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">Data Retention and Deletion Policy</h2>
                        <p>We retain your data only as long as necessary to provide our services:</p>
                        <ul className="list-disc px-6">
                            <li><strong>Email and Bookmarks:</strong> Retained for the duration of your account's active status.</li>
                            <li><strong>Archived Links:</strong> Automatically deleted after 90 days to maintain a minimal data footprint.</li>
                            <li><strong>Account Deletion:</strong> If you delete your account, all associated data will be permanently erased from our systems within 30 days.</li>
                        </ul>
                        <p></p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">Your Rights Under GDPR</h2>
                        <p>As a Gemt user, you have the following rights:</p>
                        <ul className="list-disc px-6">
                            <li><strong>Right to Access:</strong> Request a copy of all personal data we hold about you.</li>
                            <li><strong>Right to Rectification:</strong> Correct any inaccuracies in your personal data.</li>
                            <li><strong>Right to Erasure:</strong> Request deletion of your personal data at any time.</li>
                            <li><strong>Right to Data Portability:</strong> Obtain your data in a structured, machine-readable format.</li>
                            <li><strong>Right to Object:</strong> Object to the processing of your data for any reason.</li>
                        </ul>
                        <p>To exercise these rights, please contact us at gdpr@gemt.app.</p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">Compliance Measures</h2>
                        <ul className="list-disc px-6">
                            <li><strong>Consent for Non-Essential Data:</strong> Since all data stored in local storage is strictly necessary for the operation of the site, consent banners are not displayed.</li>
                            <li><strong>Transparency:</strong> All data usage is transparently disclosed in the privacy policy.</li>
                            <li><strong>Third-Party Services</strong> Umami’s GDPR compliance ensures that analytics tracking aligns with EU regulations.</li>
                        </ul>

                        <h2 className="mt-6 mb-4 uppercase font-medium">No Resale or Sharing of Data</h2>
                        <p>At Gemt, we do not and will never sell, trade, or share your personal data, including your email address, with third parties for any purpose.</p>


                        <h2 className="mt-6 mb-4 uppercase font-medium">Security Measures</h2>
                        <p>We employ industry-standard security measures to protect your data, including:</p>
                        <ul className="list-disc px-6">
                            <li><strong>Encryption:</strong> All data is transmitted over HTTPS.</li>
                            <li><strong>Data Minimization:</strong> Only essential data is collected and stored.</li>
                            <li><strong>Regular Audits:</strong>  Conduct routine reviews of data practices to ensure ongoing compliance with GDPR.</li>
                        </ul>
                        <p></p>


                        <h2 className="mt-6 mb-4 uppercase font-medium">Transparency About Non-Personal Data</h2>
                        <p>The bookmarks you save on Gemt are typically URLs from the public web and are not treated as personal data unless they include identifiable information. For businesses using Gemt, these bookmarks are considered non-personal data related to the company itself.</p>

                        <h2 className="mt-6 mb-4 uppercase font-medium">Contact Us</h2>
                        <p>If you have any questions or concerns about our data handling practices or GDPR compliance, please reach out:</p>
                        <br/>
                        <p><strong>Email:</strong> gdpr@gemt.app</p>
                        <br/>
                        <p>Thank you for trusting Gemt to provide a secure and transparent bookmarking service.</p>


                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Terms
