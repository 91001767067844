import { makeAutoObservable, runInAction } from "mobx"
import { LinkService } from "../services/LinkService"
import RootStore from "./RootStore"
import { Tag } from "../stores/TagsStore"

enum Order {
    Asc,
    Desc,
}

export enum Sorting {
    TitleAsc = 1,
    TitleDesc,
    DateAsc,
    DateDesc,
    SiteAsc,
    SiteDesc,
}

export class Link {
    id: string
    url: string
    host: string
    scheme: string
    title: string
    description: string
    imageUrl: string
    notes: string | null
    favorite: boolean
    archived: boolean
    linkType: string
    tags: string[]
    vaultId: string
    collectionId: string
    creatorId: string
    indexedAt: string
    createdAt: string
    modifiedAt: string

    constructor(
        id: string,
        url: string,
        host: string,
        scheme: string,
        title: string,
        description: string,
        imageUrl: string,
        notes: string | null,
        favorite: boolean,
        archived: boolean,
        linkType: string,
        tags: string[],
        vaultId: string,
        collectionId: string,
        createtorId: string,
        indexedAt: string,
        createdAt: string,
        modifiedAt: string,
    ) {
        makeAutoObservable(this)
        this.id = id
        this.url = url
        this.host = host
        this.scheme = scheme
        this.title = title
        this.description = description
        this.imageUrl = imageUrl
        this.notes = notes
        this.favorite = favorite
        this.archived = archived
        this.linkType = linkType
        this.tags = tags
        this.vaultId = vaultId
        this.collectionId = collectionId
        this.creatorId = createtorId
        this.indexedAt = indexedAt
        this.createdAt = createdAt
        this.modifiedAt = modifiedAt
    }

    toggleFavorite() {
        this.favorite = !this.favorite
    }

    toggleArchived() {
        this.archived = !this.archived
    }
}

class LinksStore {
    rootStore: RootStore
    links: Link[] = []
    link: Link | null = null
    isLoading: boolean = false
    updating: boolean = false
    error: any = null
    linkService: LinkService

    // `this` from rootstore passed to the constructor and we can
    // assign it to a variable accessible in this class called
    // `rootStore`. Therefore, we can access other store like
    // useStore for e.g (this.rootStore.userStore)
    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            linkService: false
        })

        this.rootStore = rootStore
        this.linkService = new LinkService()
    }

    fetchLinksForCategory = async (category: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }


        this.isLoading = true
        this.error = null

        try {
            const response = await this.linkService.getLinksForCategory(category, vaultId, authToken)

            runInAction(() => {
                this.links = response.map(l => new Link(
                    l.id,
                    l.url,
                    l.host,
                    l.scheme,
                    l.title,
                    l.description,
                    l.imageUrl,
                    l.notes,
                    l.favorite,
                    l.archived,
                    l.linkType,
                    l.tags,
                    l.vaultId,
                    l.collectionId,
                    l.creatorId,
                    l.indexedAt,
                    l.createdAt,
                    l.modifiedAt,
                ))
                this.isLoading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.isLoading = false
            })
        }
    }

    fetchLinksForCollection = async (collectionId: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.isLoading = true
        this.error = null

        try {
            const response = await this.linkService.getLinksForCollection(vaultId, collectionId, authToken)

            runInAction(() => {
                this.links = response.map(l => new Link(
                    l.id,
                    l.url,
                    l.host,
                    l.scheme,
                    l.title,
                    l.description,
                    l.imageUrl,
                    l.notes,
                    l.favorite,
                    l.archived,
                    l.linkType,
                    l.tags,
                    l.vaultId,
                    l.collectionId,
                    l.creatorId,
                    l.indexedAt,
                    l.createdAt,
                    l.modifiedAt,
                ))
                this.isLoading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.isLoading = false
            })
        }
    }

    fetchLinksForTag = async (tagId: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.isLoading = true
        this.error = null

        try {
            const response = await this.linkService.getLinksForTag(vaultId, tagId, authToken)

            runInAction(() => {
                this.links = response.map(l => new Link(
                    l.id,
                    l.url,
                    l.host,
                    l.scheme,
                    l.title,
                    l.description,
                    l.imageUrl,
                    l.notes,
                    l.favorite,
                    l.archived,
                    l.linkType,
                    l.tags,
                    l.vaultId,
                    l.collectionId,
                    l.creatorId,
                    l.indexedAt,
                    l.createdAt,
                    l.modifiedAt,
                ))
                this.isLoading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.isLoading = false
            })
        }
    }

    fetchLinkDetails = async (linkId: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.link = null
        this.isLoading = true
        this.error = null

        try {
            const response = await this.linkService.getLink(vaultId, linkId, authToken)

            runInAction(() => {
                this.link = new Link(
                    response.id,
                    response.url,
                    response.host,
                    response.scheme,
                    response.title,
                    response.description,
                    response.imageUrl,
                    response.notes,
                    response.favorite,
                    response.archived,
                    response.linkType,
                    response.tags.sort(),
                    response.vaultId,
                    response.collectionId,
                    response.creatorId,
                    response.indexedAt,
                    response.createdAt,
                    response.modifiedAt,
                )
                this.isLoading = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.isLoading = false
            })
        }
    }

    addNewLink = async (url: string, authToken?: string, collectionId?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updating = true
        this.error = null
        var link: Link | null = null

        try {
            const response = await this.linkService.addLink(url, vaultId, authToken, collectionId)
            runInAction(() => {
                link = new Link(
                    response.id,
                    response.url,
                    response.host,
                    response.scheme,
                    response.title,
                    response.description,
                    response.imageUrl,
                    response.notes,
                    response.favorite,
                    response.archived,
                    response.linkType,
                    response.tags,
                    response.vaultId,
                    response.collectionId,
                    response.creatorId,
                    response.indexedAt,
                    response.createdAt,
                    response.modifiedAt,
                )
                this.links.unshift(link)
                // this.updating = false
            })
            const update = await this.linkService.indexLink(vaultId, response.id, authToken)
            runInAction(() => {
                link!.title = update.title
                link!.description = update.description
                link!.imageUrl = update.imageUrl
                this.updating = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.updating = false
            })
        }
    }

    addTagsToLink = async (tags: Tag[], linkId: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        const tagIds = tags.map((tag) => tag.id)
        const tagNames = tags.map((tag) => tag.name)

        this.updating = true
        this.error = null

        try {
            await this.linkService.addTagsToLink(tagIds, linkId, vaultId, authToken)
            runInAction(() => {
                this.link?.tags.push(...tagNames)
                this.link?.tags.sort()
                this.updating = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.updating = false
            })
        }
    }

    removeTagsFromLink = async (tags: Tag[], linkId: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        const tagIds = tags.map((tag) => tag.id)
        const tagNames = tags.map((tag) => tag.name)

        this.updating = true
        this.error = null

        try {
            await this.linkService.removeTagsFromLink(tagIds, linkId, vaultId, authToken)
            runInAction(() => {
                if (this.link?.tags) {
                    this.link.tags = this.link.tags.filter(tag => !tagNames.includes(tag))
                }
                this.updating = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.updating = false
            })
        }
    }

    updateLinkTitle = async (link: Link, title: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updating = true
        this.error = null

        try {
            await this.linkService.updateTitle(vaultId, link.id, title, authToken)
            runInAction(() => {
                link.title = title
                this.updating = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.updating = false
            })
        }
    }

    updateLinkDescription = async (link: Link, description: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updating = true
        this.error = null

        try {
            await this.linkService.updateDescription(vaultId, link.id, description, authToken)
            runInAction(() => {
                link.description = description
                this.updating = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.updating = false
            })
        }
    }

    updateLinkNotes = async (link: Link, notes: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updating = true
        this.error = null

        try {
            await this.linkService.updateNotes(vaultId, link.id, notes, authToken)
            runInAction(() => {
                link.notes = notes
                this.updating = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.updating = false
            })
        }
    }

    updateLinkCollection = async (link: Link, collectionId: string, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updating = true
        this.error = null

        try {
            await this.linkService.updateCollection(vaultId, link.id, collectionId, authToken)
            runInAction(() => {
                link.collectionId = collectionId
                this.updating = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.updating = false
            })
        }
    }

    toggleFavorite = async (link: Link, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updating = true
        this.error = null

        if (link) {
            try {
                await this.linkService.toggleFavorite(vaultId, link.id, !link.favorite, authToken)
                runInAction(() => {
                    link!.toggleFavorite()
                    this.updating = false
                })
            } catch (error: any) {
                runInAction(() => {
                    this.error = error.message
                    this.updating = false
                })
            }
        }
    }

    toggleArchived = async (link: Link, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updating = true
        this.error = null

        if (link) {
            try {
                await this.linkService.toggleArchived(vaultId, link.id, !link.archived, authToken)
                runInAction(() => {
                    link!.toggleArchived()
                    this.updating = false
                })
            } catch (error: any) {
                runInAction(() => {
                    this.error = error.message
                    this.updating = false
                })
            }
        }
    }

    searchForLinks = async (searchString?: string | null, lang?: string | null, authToken?: string): Promise<void> => {
        const vaultId = this.rootStore.vaultsStore.selectedVault
        if (!authToken || !vaultId) {
            return
        }

        this.updating = true
        this.error = null

        try {
            const response = await this.linkService.search(vaultId, authToken!, searchString!, lang)
            runInAction(() => {
                this.links = response.map(l => new Link(
                    l.id,
                    l.url,
                    l.host,
                    l.scheme,
                    l.title,
                    l.description,
                    l.imageUrl,
                    l.notes,
                    l.favorite,
                    l.archived,
                    l.linkType,
                    l.tags,
                    l.vaultId,
                    l.collectionId,
                    l.creatorId,
                    l.indexedAt,
                    l.createdAt,
                    l.modifiedAt,
                ))
                this.updating = false
            })
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message
                this.updating = false
            })
        }
    }

    sortLinksBy = async (sort: Sorting) => {
        switch (sort) {
            case Sorting.DateAsc: {
                this.sortLinksByDate(Order.Asc)
                break
            }
            case Sorting.DateDesc: {
                this.sortLinksByDate(Order.Desc)
                break
            }
            case Sorting.TitleAsc: {
                this.sortLinksByTitle(Order.Asc)
                break
            }
            case Sorting.TitleDesc: {
                this.sortLinksByTitle(Order.Desc)
                break
            }
            case Sorting.SiteAsc: {
                this.sortLinksBySite(Order.Asc)
                break
            }
            case Sorting.SiteDesc: {
                this.sortLinksBySite(Order.Desc)
                break
            }
            default: {
                break
            }
        }
    }

    //region - Sorting
    private sortLinksByTitle = async (order: Order): Promise<void> => {
        if (order == Order.Asc) {
            this.links.sort((a, b) => a.title.localeCompare(b.title))
        } else {
            this.links.sort((a, b) => b.title.localeCompare(a.title))
        }
    }

    private sortLinksByDate = async (order: Order): Promise<void> => {
        this.links.sort((a, b) => {
            if (a.createdAt > b.createdAt) return order === Order.Asc ? 1 : -1
            if (a.createdAt < b.createdAt) return order === Order.Asc ? -1 : 1
            return 0
        })
    }

    private sortLinksBySite = async (order: Order): Promise<void> => {
        if (order == Order.Asc) {
            this.links.sort((a, b) => a.url.localeCompare(b.url))
        } else {
            this.links.sort((a, b) => b.url.localeCompare(a.url))
        }
    }
    //endregion
}

export default LinksStore
