import AddCollectionButton from "./AddCollectionButton"
import Categories from "./Categories"
import Collections from "./Collections"
import Tags from "./Tags"

function SidebarContents() {
    return (
        <div className="overflow-y-auto px-3 py-3 flex h-full flex-col">
            {/* <div className="h-auto">
                    <Vaults selectedVault={selectedVault} setSelectedVault={setSelectedVault} />
                </div> */}
            <div className="h-auto">
                <Categories />
            </div>
            <div className="h-auto mt-4">
                <Collections />
            </div>
            <div className="h-auto mt-4">
                <Tags />
            </div>
            <div className="flex-grow">
            </div>
            <div className="h-auto">
                <AddCollectionButton />
            </div>
        </div>
    )
}

export default SidebarContents
